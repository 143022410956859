import React from "react"
import PropTypes from "prop-types"

import { Fade } from "react-awesome-reveal";

const DiamondHero = ({ headings }) => {

  return (
    <Fade delay={750} triggerOnce>
      <div className="bg-pink pt-30 pb-25">
        <div className="gutter">
          <div className="relative">
            <svg
              className="fill-current xl:h-200 mx-auto"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 329.31 394.74"
            >
              <path d="M164.65 0L0 113.97v102.65l164.65 178.12 164.66-178.12V113.97L164.65 0z" />
            </svg>
            <h2 className="font-heading text-4xl md:text-7xl lg:text-8xl xl:text-9xl font-black text-white uppercase text-center absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 w-full">
              {headings.map(heading => (
                <span className="block">
                  {heading.text}
                </span>
              ))}
            </h2>
          </div>
        </div>
      </div>
    </Fade>
  )
}

DiamondHero.propTypes = {
  headings: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
    })
  ),
}

DiamondHero.defaultProps = {}

export default DiamondHero
