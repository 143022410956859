import React from "react"
import PropTypes from "prop-types"

import { Fade } from "react-awesome-reveal"

const Video = ({ video, heading }) => {
  return (
    <Fade delay={750} triggerOnce>
      <div className="relative bg-charcoal">
        <video
          className="w-full"
          muted
          loop
          playsInline
          autoPlay
          src={video}
        ></video>
        <div className="absolute inset-0 flex items-center justify-center">
          {heading && (
            <h2 className="px-4 md:px-12 font-heading text-fluid-4xl md:text-7xl lg:text-8xl xl:text-9xl font-black text-white uppercase text-center w-full whitespace-pre">
              {heading}
            </h2>
          )}
        </div>
      </div>
    </Fade>
  )
}

Video.propTypes = {
  video: PropTypes.string.isRequired,
  heading: PropTypes.string,
}

Video.defaultProps = {}

export default Video
