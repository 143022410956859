import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { removeCraftBaseUrlPrefix } from "../utils/string-utils"

import { Fade } from "react-awesome-reveal";

const Callout = ({ preheadingLink, headings, text }) => {
  // Craft CMS defaults empty link text to "Learn More". We should not display these links.
  const shouldRenderPreheadingLink = () =>
    preheadingLink && preheadingLink.text.toLowerCase() !== "learn more"

  return (
    <Fade delay={400} triggerOnce>
      <div className="relative py-35 xl:py-50">
        <div className="text-center gutter">
          {shouldRenderPreheadingLink() && (
            <Link
              className="font-heading font-medium text-lg xl:text-xl text-dark-blue uppercase inline-flex items-center justify-center w-full space-x-2 mt-10 xl:mt-15 absolute top-0 left-0"
              to={removeCraftBaseUrlPrefix(preheadingLink.url) || "/tyot"}
            >
              <span className="pt-1">{preheadingLink.text}</span>
              <svg
                className="fill-current h-3"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 23.69 15.81"
              >
                <path d="M15.85 0l-2.12 2.12 4.22 4.22H0v3h17.95l-4.35 4.35 2.12 2.12 7.97-7.97L15.85 0z" />
              </svg>
            </Link>
          )}

          <h2 className="font-heading text-fluid-2xl sm:text-2xl lg:text-4xl xl:text-6xl font-black uppercase lg:space-y-2">
            {headings.map(heading => (
              <span
                className={`block ${heading.textWeight === "light" ? "font-light" : "font-black"
                  }`}
              >
                {heading.text}
              </span>
            ))}
          </h2>
          <div
            className="lg:text-lg xl:text-xl font-light max-w-4xl mx-auto mt-5"
            dangerouslySetInnerHTML={{ __html: text }}
          ></div>
        </div>
      </div>
    </Fade>
  )
}

Callout.propTypes = {
  preheadingLink: PropTypes.shape({
    text: PropTypes.string.isRequired,
    to: PropTypes.string,
  }),
  headings: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      textWeight: PropTypes.string.isRequired,
    })
  ),
  text: PropTypes.string,
}

Callout.defaultProps = {}

export default Callout
