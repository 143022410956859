import React from "react"
import PropTypes from "prop-types"

import { Fade } from "react-awesome-reveal";

const ColumnedText = ({ columns, logos }) => {
  return (
    <Fade delay={400} triggerOnce>
      <div className="bg-pink py-20 xl:py-40">
        <div className="gutter">
          <div className="-mb-20 xl:flex xl:-ml-75">
            {columns.map(column => (
              <div className="pb-20 xl:w-1/2 xl:pl-75">
                <h3 className="font-heading text-xl xl:text-3xl font-medium uppercase mb-5">
                  {column.header}
                </h3>
                <div
                  className="lg:text-lg xl:text-xl font-light"
                  dangerouslySetInnerHTML={{ __html: column.text }}
                ></div>
                {column.link.url && (
                  <a
                    className="font-heading font-medium text-xl text-dark-blue uppercase inline-flex items-center space-x-2 mt-8"
                    target="_blank"
                    rel="noreferrer"
                    href={column.link.url}
                  >
                    <span className="pt-1">{column.link.customText}</span>
                    <svg
                      className="fill-current h-3"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 23.69 15.81"
                    >
                      <path d="M15.85 0l-2.12 2.12 4.22 4.22H0v3h17.95l-4.35 4.35 2.12 2.12 7.97-7.97L15.85 0z" />
                    </svg>
                  </a>
                )}
              </div>
            ))}
          </div>
          <div className="mt-15 xl:mt-20">
            <ul className="flex flex-wrap lg:flex-nowrap items-center justify-between -mb-10">
              {logos.map((logo, logoIndex) => (
                <li className={`${logoIndex % 2 ? "text-right lg:text-left" : "text-left"} w-1/2 lg:w-auto pb-10`}>
                  <img
                    className={`${logoIndex === 1 ? "h-8 xl:h-12" : "h-4 xl:h-6"} inline-block`} // Hotfix: We need to get this from the CMS!
                    src={logo.localFile.publicURL}
                    alt={logo.title}
                  />
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </Fade>
  )
}

ColumnedText.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      header: PropTypes.string,
      link: PropTypes.shape({
        url: PropTypes.string,
        customText: PropTypes.string,
      }),
    })
  ),
  logos: PropTypes.arrayOf({
    title: PropTypes.string,
    localFile: PropTypes.shape({
      publicURL: PropTypes.string,
    }),
  }),
}

ColumnedText.defaultProps = {}

export default ColumnedText
