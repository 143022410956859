import React from "react"
import PropTypes from "prop-types"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { Fade } from "react-awesome-reveal";

const People = ({ people }) => {
  return (
    <Fade delay={400} triggerOnce>
      <div className="bg-pink py-20 xl:py-40">
        <div className="gutter">
          <div className="text-xl -mb-20 md:flex md:flex-wrap md:-ml-20 xl:-ml-30">
            {people.map(person => (
              <div
                className="pb-20 md:w-1/2 xl:w-1/3 md:pl-20 xl:pl-30"
                key={person.id.toLowerCase()}
              >
                <figure className="aspect-w-3 aspect-h-4 bg-white bg-opacity-50 mb-10">
                  <GatsbyImage image={getImage(person.image[0].localFile)} alt={person.image[0].title} style={{
                    position: 'absolute', // To override Gatsby Image's 'relative' override
                  }} />
                </figure>
                <h3 className="font-heading font-medium uppercase mb-2">
                  {person.title}
                </h3>
                <ul>
                  <li className="font-medium">{person.position}</li>
                </ul>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Fade>
  )
}

People.propTypes = {
  people: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      position: PropTypes.string,
      image: PropTypes.arrayOf(PropTypes.shape({})),
    })
  ),
}

People.defaultProps = {}

export default People
