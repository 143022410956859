import React from "react"
import PropTypes from "prop-types"

import { Fade } from "react-awesome-reveal";

const EmbeddedMedia = ({ code, caption, textColour, backgroundColour }) => {
  return (
    <Fade delay={400} triggerOnce>
      <div
        className="py-30"
        style={{ backgroundColor: backgroundColour, color: textColour }}
      >
        <div className="gutter">
          <div
            className="aspect-w-16 aspect-h-9"
            dangerouslySetInnerHTML={{ __html: code }}
          ></div>
          <h4 className="font-heading font-medium uppercase text-center mt-10">
            {caption}
          </h4>
        </div>
      </div>
    </Fade>
  )
}

EmbeddedMedia.propTypes = {
  code: PropTypes.string,
  caption: PropTypes.string,
}

EmbeddedMedia.defaultProps = {}

export default EmbeddedMedia
