import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { removeCraftBaseUrlPrefix } from "../utils/string-utils"

import { Fade } from "react-awesome-reveal";

const ContentWork = ({ caseStudies }) => {
  return (
    <Fade delay={400} triggerOnce>
      <div className="md:flex md:flex-wrap">
        {caseStudies.map(caseStudy => (
          <div className="md:w-1/2">
            <Link
              className="block relative group"
              to={`${removeCraftBaseUrlPrefix(caseStudy.url)}`}
            >
              <figure className="group-hover:opacity-75 group-focus:opacity-75">
                <GatsbyImage
                  className="w-full"
                  alt={caseStudy.title}
                  image={getImage(caseStudy.image[0].localFile)}
                />
              </figure>
              <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 mb-20 xl:mb-40">
                <div className="font-heading font-medium lg:text-xl uppercase text-center bg-white pt-1 px-12">
                  {caseStudy.title}
                </div>
              </div>
            </Link>
          </div>
        ))}
      </div>
    </Fade>
  )
}

ContentWork.propTypes = {
  caseStudies: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.string,
      image: PropTypes.arrayOf(
        PropTypes.shape({
          localFile: PropTypes.shape(),
        })
      ),
    })
  ),
}

ContentWork.defaultProps = {}

export default ContentWork
