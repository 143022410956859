import React from "react"
import PropTypes from "prop-types"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { Fade } from "react-awesome-reveal";

const Images = ({ images }) => {
  return (
    <Fade delay={400} triggerOnce>
      <div className="lg:flex">
        {images.map(image => (
          <div
            key={image.id}
            className="lg:w-1/2 p-10 lg:p-15"
            style={{ backgroundColor: image.backgroundColour }}
          >
            <GatsbyImage
              className="object-contain w-full h-full"
              alt=""
              image={getImage(image.image)}
            />
          </div>
        ))}
      </div>
    </Fade>
  )
}

Images.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      backgroundColour: PropTypes.string,
      image: PropTypes.shape(),
    })
  ),
}

Images.defaultProps = {}

export default Images
