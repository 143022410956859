import React from "react"
import PropTypes from "prop-types"

import { Fade } from "react-awesome-reveal"

const Audio = ({ audioItems, textColour, backgroundColour }) => {
  if (audioItems.length === 0) return null
  return (
    <Fade delay={400} triggerOnce>
      <div
        className="py-30"
        style={{ backgroundColor: backgroundColour, color: textColour }}
      >
        <div className="gutter space-y-20">
          {audioItems.map(({ caption, file }, index) => {
            return (
              <div key={`audio-item-${index}`} className="max-w-5xl mx-auto">
                <figure>
                  <audio className="w-full" controls>
                    <source src={file[0].url} type="audio/mpeg"></source>
                  </audio>
                </figure>
                {caption && (
                  <h4 className="font-heading font-medium uppercase text-center mt-10">
                    {caption}
                  </h4>
                )}
              </div>
            )
          })}
        </div>
      </div>
    </Fade>
  )
}

Audio.propTypes = {
  audioUrl: PropTypes.string,
  caption: PropTypes.string,
}

Audio.defaultProps = {}

export default Audio
