import React from "react"
import PropTypes from "prop-types"
import HubspotForm from "react-hubspot-form"
import { Fade } from "react-awesome-reveal"

const HubspotEmbed = ({ formId, textColour, backgroundColour }) => {
  return (
    <Fade delay={400} triggerOnce>
      <div
        className="py-30"
        style={{ backgroundColor: backgroundColour, color: textColour }}
      >
        <div className="gutter">
          <div className="max-w-5xl mx-auto">
            <HubspotForm
              portalId={process.env.GATSBY_HUBSPOT_PORTAL_ID}
              formId={formId}
            />
          </div>
        </div>
      </div>
    </Fade>
  )
}

HubspotEmbed.propTypes = {
  formId: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string.isRequired,
  textColour: PropTypes.string.isRequired,
}

export default HubspotEmbed
