import React from "react"
import PropTypes from "prop-types"

import { Fade } from "react-awesome-reveal";

const Logos = ({ heading, logos }) => {
  const row1 = logos.slice(0, 3);
  const row2 = logos.slice(3, 4);
  const row3 = logos.slice(4, 7);

  return (
    <Fade delay={400} triggerOnce>
      <div className="relative pt-35 xl:pt-50 py-20 xl:py-40">
        <div className="gutter">
          <h2 className="font-heading text-xl xl:text-3xl font-medium text-center w-full absolute top-0 left-0 uppercase mt-10 xl:mt-20">
            {heading}
          </h2>
          <div>

            {/* First Row */}

            <ul className="flex items-start justify-center space-x-10 lg:space-x-20 xl:space-x-25">
              {row1.map((logo, index) => (
                <li
                  key={`logo-row-1-${index}`}
                  className={`w-24 sm:w-32 md:w-48 xl:w-56 ${
                    index === 1 ? "" : "pt-15"
                  }`}
                >
                  <figure className="aspect-w-16 aspect-h-9">
                    <img
                      className="object-contain"
                      src={logo.logo}
                      alt=""
                    />
                  </figure>
                </li>
              ))}
            </ul>

            {/* Middle Row */}

            <ul className="flex items-start justify-center space-x-10 lg:space-x-20 xl:space-x-25">
              {row2.map((logo, index) => (
                <li
                  key={`logo-row-2-${index}`}
                  className="w-24 sm:w-32 md:w-48 xl:w-56"
                >
                  <figure className="aspect-w-16 aspect-h-9">
                    <img
                      className="object-contain"
                      src={logo.logo}
                      alt=""
                    />
                  </figure>
                </li>
              ))}
            </ul>

            {/* Last Row */}

            <ul className="flex items-start justify-center space-x-10 lg:space-x-20 xl:space-x-25">
              {row3.map((logo, index) => (
                <li
                  key={`logo-row-3-${index}`}
                  className={`w-24 sm:w-32 md:w-48 xl:w-56 ${
                    index === 1 ? "pt-15" : ""
                  }`}
                >
                  <figure className="aspect-w-16 aspect-h-9">
                    <img
                      className="object-contain"
                      src={logo.logo}
                      alt=""
                    />
                  </figure>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </Fade >
  )
}

Logos.propTypes = {
  heading: PropTypes.string,
  logos: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      logo: PropTypes.string, // logo source to be used as <img /> src
    })
  ),
}

Logos.defaultProps = {}

export default Logos
