import React from "react"
import PropTypes from "prop-types"

import Images from "./images"
import ContentWork from "./content-work"
import EmbeddedMedia from "./embedded-media"
import Audio from "./audio"
import ColumnedText from "./columned-text"
import People from "./people"
import Logos from "./logos"
import Callout from "./callout"
import DiamondHero from "./diamond-hero"
import Video from "./video"
import HubspotEmbed from "./hubspotForm"

const FlexibleContentWrapper = ({ flexibleContent }) => {
  // Dynamically render flexible content components based on the `typeHandle`.
  const renderFlexibleContentComponent = (typeHandle, content) => {
    switch (typeHandle) {
      case "images":
        return (
          <Images
            images={[
              {
                id: 1,
                image: content.image1[0].localFile,
                backgroundColour: content.backgroundColour1,
              },
              {
                id: 2,
                image: content.image2[0].localFile,
                backgroundColour: content.backgroundImage2,
              },
            ]}
          />
        )
      case "caseStudies":
        return <ContentWork caseStudies={content.caseStudies} />
      case "embed":
        return (
          <EmbeddedMedia
            code={content.code}
            caption={content.caption}
            backgroundColour={content.backgroundColour}
            textColour={content.textColour}
          />
        )
      case "columnedText":
        return (
          <ColumnedText
            columns={[
              {
                text: content.body1,
                header: content.col_text_heading1,
                link: content.link1,
              },
              {
                text: content.body2,
                header: content.col_text_heading2,
                link: content.link2,
              },
            ]}
            logos={content.logos}
          />
        )
      case "people":
        return <People people={content.people} />
      case "logos":
        return (
          <Logos
            heading={content.logo_heading}
            logos={content.logos.map(logo => ({
              id: logo.id,
              logo: logo.localFile.publicURL,
            }))}
          />
        )
      case "callOut":
        return (
          <Callout
            preheadingLink={content.preheadingLink}
            headings={content.callout_heading}
            text={content.text}
          />
        )
      case "audio": // Audio
        return (
          <Audio
            audioItems={content.audioItem}
            backgroundColour={content.backgroundColour}
            textColour={content.textColour}
          />
        )
      case "hero": // Hero
        return <DiamondHero headings={content.hero_heading} />

      case "video": // Video
        return <Video video={content.file[0].url} heading={content.heading} />

      case "hubspotForm": // Hubspot form
        return (
          <HubspotEmbed
            id={content.id}
            formId={content.formId}
            backgroundColour={content.backgroundColour}
            textColour={content.textColour}
          />
        )
      default:
        return
    }
  }

  return (
    <>
      {flexibleContent.map((item, index) => (
        <div key={`${item.typeHandle}-${index}`}>
          {renderFlexibleContentComponent(item.typeHandle, item)}
        </div>
      ))}
    </>
  )
}

FlexibleContentWrapper.propTypes = {
  flexibleContent: PropTypes.array.isRequired,
}

FlexibleContentWrapper.defaultProps = {}

export default FlexibleContentWrapper
